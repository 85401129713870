<template lang="pug">
    ul.navigation
      li.navigation__item(
        v-for="(item, $index) in items"
        :key="$index"
      )
        router-link(
          class="navigation__link"
          :active-class="'navigation__link--active'"
          v-if="isLogin || item.public"
          :to="{ name: item.link }"
          exact
        ) {{ item.name }}
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isLogin: {
      type: Boolean,
      default: false
    }
  }
}
</script>
