<template lang="pug">
  header.header
    .header__container.container

      //- Верхняя линия шапки
      .header__top-line

        //- Логотип
        router-link.header__logo(:to="{name: 'Main'}")
          img(
            src="@/assets/images/main-logo.png"
            width="68"
            height="59"
            alt="Логотип ГАР Калужской области"
          )

        //- Заголовок
        .header__title {{ header }}

        //- Аккаунт
        //- Выход
        .header__account(v-if="isLogin")
          button.btn.btn--main(@click="logout()") Выход
        //- Вход
        .header__account(v-else)
          button.btn.btn--main(@click="$router.push({ name: 'Login' })") Войти
      //- Навигация шапки
      .header__nav
        navigation(
          :items="navs"
          :isLogin="isLogin"
        )
</template>

<script>
import navigation from './components/navigation'
export default {
  components: {
    navigation
  },
  data() {
    return {
      header: 'Государственный реестр Калужской области',
      navs: [
        {
          name: 'Описание',
          type: 'link',
          link: 'Main',
          public: true
        },
        {
          name: 'Поиск',
          type: 'link',
          link: 'Search',
        },
        {
          name: 'Обновления',
          type: 'link',
          link: 'Updates',
        },
        {
          name: 'Статистика',
          type: 'link',
          link: 'Statistics',
        },
        {
          name: 'Правка записей',
          type: 'link',
          link: 'ModelChanges',
        },
        {
          name: 'Журнал изменений',
          type: 'link',
          link: 'ChangeLog',
        },
        {
          name: 'Объекты',
          type: 'link',
          link: 'Objects',
        },
      ],
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: 'Login' })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
}
</style>