<template lang="pug">
  .page-wrapper
    .page
      a-header
      .page__body(
        ref="pageBody"
      )
        router-view
      a-footer
</template>

<script>
import AHeader from "../components/a-header/a-header";
import AFooter from "../components/a-footer/a-footer";
export default {
  name: "Base",
  components: {
    AHeader,
    AFooter,
	},
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>
