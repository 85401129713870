<template lang="pug">
  footer.footer
    .footer__container.container
      p.footer__text {{ support }}
      .footer__block
        p.footer__text {{ copyright }}
        p.footer__text {{ lastUpdate }}
</template>

<script>
export default {
  data() {
    return {
      support: 'Техническая поддержка',
      copyright: '© 2005 - 2021 ГАР КО',
      lastUpdate: 'Последнее обновление  от 08.10.2021'
    }
  },
}
</script>